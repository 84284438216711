@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  @apply bg-neutral-100;
}

.SliderRoot {
  position: relative;
  display: flex;
  align-items: center;
  user-select: none;
  touch-action: none;
  width: 100%;
  height: 20px;
}

.SliderTrack {
  background-color: #00000030;
  position: relative;
  flex-grow: 1;
  border-radius: 9999px;
  height: 3px;
}

.SliderRange {
  position: absolute;
  background-color: black;
  border-radius: 9999px;
  height: 100%;
}

.SliderThumb {
  display: block;
  width: 20px;
  height: 20px;
  background-color: black;
  box-shadow: 0 2px 10px #00000030;
  border-radius: 10px;
}
.SliderThumb:hover {
  background-color: black;
}
.SliderThumb:focus {
  outline: none;
  box-shadow: 0 0 0 5px var(--black-a8);
}

/* removing scroll-bar visibility in spotify lyrics controller */
::-webkit-scrollbar {
  display: none;
  scroll-behavior: smooth;
  overscroll-behavior-y: contain;
}

* {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.github-comment-content-body-wrapper > * {
  @apply mb-3;
}

.github-comment-content-body-wrapper > ul {
  @apply list-inside list-disc;
}

.github-comment-content-body-wrapper > ul > li {
  @apply list-item;
}

.github-comment-content-body-wrapper > h1 {
  @apply text-2xl font-semibold;
}

.github-comment-content-body-wrapper > h2 {
  @apply text-xl font-semibold;
}

.github-comment-content-body-wrapper > h3 {
  @apply text-lg font-medium;
}

.github-comment-content-body-wrapper > h4 {
  @apply text-base font-medium;
}

.github-comment-content-body-wrapper a {
  @apply text-blue-500 hover:underline;
}

.desktop-view-container {
  @apply bg-gray-100 flex flex-row items-end justify-center;
  background-image: url('./crafts/media/wallpaper-dessert.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}


/* styles for card animations grid */
.card-animation {
  animation: 0.6s cardRenderAnimation ease-in-out;
  @apply hover:shadow-xl hover:shadow-neutral-200/40 focus:scale-95 truncate;
}

@keyframes cardRenderAnimation {
  0% {
    opacity: 0;
    scale: 108%;
    @apply drop-shadow-xl shadow-neutral-200/50;
  }
  25% {
    opacity: 0.25;
    scale: 106%;
    @apply drop-shadow-lg shadow-neutral-200/50;
  }
  50% {
    opacity: 0.50;
    scale: 104%;
    @apply drop-shadow-md shadow-neutral-200/50;
  }
  75% {
    opacity: 0.75;
    scale: 102%;
    @apply drop-shadow shadow-neutral-200/50;
  }
  100% {
    opacity: 1;
    scale: 100%;
    @apply drop-shadow-sm shadow-neutral-200/50;
  }
}

